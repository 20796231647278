import React, { useEffect, useMemo, useRef, useState } from 'react';
import  { Box, Container, Grid, Link} from '@mui/material';
import styled from '@emotion/styled';
import Paragraph from '../../Paragraph';
import {motion} from 'framer-motion';
import UperBar from '../../UperBar';
import axios from 'axios'; 
import {API_URL} from "../../../Constant/index" 
import LockerMenu from '../../LockerMenu'
import { Img } from '../../../Styles/Maintext.styles'; 
import coin from "../../../../../src/assets/Images/newDesign/coin.png"
const MotionGrid = motion(Grid);

const HeaderDesign = styled.div`
  min-height: 100dvh; 
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media(max-width: 900px){
    min-height: 100vh;
  }
  
`
const BgVideo = styled.video`
  width:100%;
  height:calc(100vh - 0px);
  position:absolute; 
  top:0;
  left:0;
  object-fit:cover;
  display :block ;
  @media(max-width:499px){  
    display: none;
  }
` 
const BgVideo2 = styled.video`
  width:100%;
  height:calc(100vh - 100px);
  position:absolute; 
  /* z-index: 999; */
  top:0%;
  left:50%;
  transform: translate(-50%,0%);
  display: none;
  object-fit: cover;
  @media(max-width:499px){  
    display: block !important; 
  } 
`
const Spantype = styled.span`
  font-size: 54px; 
  @media(max-width:599px){  
    font-size: 21px !important; 
  }
`
 
const CustomGrid = styled(Grid)`  
   background-color: #161616; 
   border-radius: 10px;
   display: flex;  
   flex-direction: column; 
   justify-content: start; 
   align-items: center; 
   gap:10px; 
  
`
const WhitelabelHome = () => { 
  // const [pagestate , setPagestate] =  useState(false);
  const [pagestate , setPagestate] =  useState(false);
  const [location, setLocation] = useState(null);  
  const [o_sys, setO_sys] =  useState(null); 
  const [filterdButton,setFilterdButton] = useState([]);
  const [Ips , setIps] = useState([]);
  const [status , setStatus] = useState("loading");


  useEffect(() => { 
    const timer =  setTimeout( async() => {
         try {
           const ipResponse = await axios.get('https://api.ipify.org?format=json') 
           // console.log(ipResponse.data.ip)
           const response = await axios.get(`https://ipapi.co/${ipResponse?.data.ip}/json/`)
           .then(function (response) {
               console.log("response",response);
               const data = response.data;  
                 // console.log(data)
                 setLocation({
                     ip: data?.ip, 
                     city: data?.city,
                     region: data?.region,
                     country: data?.country_name,
                     asn: data?.asn,
             isp: data?.org
           });
           })
           .catch(function (error) {
               console.error(error);
           });
           
         } catch (error) {
           console.error('Error fetching location:', error);
         }
     }, 0); // 3 seconds 
     return () => clearTimeout(timer);
   },[]); 
    
   const memoizedData = useMemo(() => {
     if (location) {
       // Perform expensive calculations or transformations here
       return location;
     }
     return [];
   }, [location]);
     
 
   useEffect(() => { 
     const timer =  setTimeout(async () => {
       // console.log(requestData)
       const endPoint = "getServerData";
       const endPoint2 = "getFreeServerData";
       const method = "GET";
       try {
         const response = await fetch(`${API_URL}${endPoint}`, {
           method,
           headers: {
             "content-type": "application/json",
           },
         });
         const responseFreeServer = await fetch(`${API_URL}${endPoint2}`, {
           method,
           headers: {
             "content-type": "application/json",
           },
         });
         const result = await response.json();
         const resultFreeServer = await responseFreeServer.json();
         // console.log("response", result);
         if (result?.message === "succes" && resultFreeServer?.message === "succes") {
           const ips = result?.data.map((server)=> server?.ip )
           const ipsFreeServer = resultFreeServer?.data.map((server)=> server?.ip )
           const newIpsList = [...ips,...ipsFreeServer]
           setIps(newIpsList)
         }
       } catch (err) {
         // setServerList([{}])
         console.error(err);
       } 
     },0)  
     return () => clearTimeout(timer);
   },[])
   useEffect(()=>{
     console.log("location?.ip",location?.ip); 
     console.log("ips",Ips.includes(location?.ip)); 
     if(Ips.length >0 && location){
 
       if(Ips?.includes(location?.ip)){  
         setStatus("Protected");    
         console.log("Status:", status)   
       }else{   
         setStatus("UnProtected");    
         console.log("status: Unrprotected")
         return;
       }
     } 
   },[Ips,location]) 
 
  const Pricing = [ 
    { 
      title:"Silver", 
      titleclr : "#ACACAC",  
      pricing: "10,000", 
      instruction : [ 
        "Access to ShadowNode white label VPN solution"
        ,  
        "Limited customization options for branding and design "
        ,   
        " Standard customer support"
        ,   
        "Android and iOS mobile apps"
        ,  
      ]
    }, 
    { 
      title:"Gold", 
      titleclr : "#FFAB3E", 
      pricing: "15,000", 
      instruction : [ 
        "Enhanced customization options for branding and design"
        ,   
        "Advanced features such ascustom server locations and protocols"
        ,   
         "Priority customer support"
        ,   
        "Android and iOS mobile app"
        ,    
        "Windows desktop app"
        , 
      ]
    }, 
    { 
      title:"Platinum", 
      titleclr : "#835DFF", 
      pricing: "20,000", 
      instruction : [ 
        "Enhanced customization options for branding and desig" 
        ,  
        "Complete website + documentation (Single landing page)" 
        ,   
        "Priority customer support" 
        ,   
        "Android and iOS mobile apps"
        ,   
        "MacOS, Linux and Windows desktop apps"
        ,  
        "Push through our kOLs", 
      ]
    },
  ] 


           {/* <Paragraph fontSize="16px" textAlign="center" fontWeight="600">  
           Access to ShadowNode's
           white label VPN solution 
          </Paragraph>  
          <Paragraph fontSize="16px" textAlign="center" fontWeight="600">  
          Limited customization options for branding and design 
          </Paragraph>  
          <Paragraph fontSize="16px" textAlign="center" fontWeight="600">   
          Standard customer support
          </Paragraph>  
          <Paragraph fontSize="16px" textAlign="center" fontWeight="600">   
          Android and iOS mobile apps
          </Paragraph> */} 

  return (
    <Box position={'relative'}>
      {/* <BgVideo muted={true} autoPlay={true} playsInLine={true} loop={true}  >
       <source  src={DeskVid} type="video/mp4"/>
      </BgVideo>  */}
      <UperBar IP={memoizedData?.ip ? memoizedData?.ip : "loading"} location={memoizedData?.city ? `${memoizedData?.city}  ${memoizedData?.region}` :"loading"} ISP={ memoizedData?.isp ? ` ${memoizedData?.isp}` :"loading"} protected={status} />
      <HeaderDesign id="home">
      <LockerMenu /> 
      <Container maxWidth="xl"  sx={{ minHeight:{xs:"calc(100vh - 150px)",lg:"calc(100vh - 120px)"} ,display:"flex", flexDirection:"column",gap:"50px 0px",justifyContent:"start",marginTop:{xs:"50px",lg:"150px"},padding:{xs:"50px 0px",lg:"80px 0px"},marginBottom:"50px",paddingLeft:{xs:"20px"},paddingRight:{xs:"20px"},background:'rgba(27, 24, 33, 0.6)',backdropFilter:'blur(10px)'}}> 
      <Paragraph textAlign="center" fontSize={{xs:"44px",sm:"55px"}} fontWeight="600" > 
             White Lable Pricing
        </Paragraph>
      <Grid container display="flex" justifyContent="center" alignItems="center" gap="40px" >  
         { 
          Pricing.map((obj,index) =>   <CustomGrid key={index} item xs={12} sm={6} lg={2.4} sx={{justifyContent:"space-between"}} >  
          <Box  display="flex" flexDirection="column"  padding="30px 10px" gap="10px 0px"  sx={{minHeight:"380px" }} >   
            <Paragraph fontSize="25px" color={obj.titleclr}  fontWeight="600">  
             {obj.title}
            </Paragraph>  
             <Box display="flex" flexDirection="column" justifyContent="start" alignItems="center" gap="10px">  
              { 
                obj.instruction.map((val) =>  <Paragraph key={val} fontSize="16px" textAlign="center" fontWeight="600">  
                {val}
               </Paragraph>  )
              } 
    
             </Box>
           </Box>
     
            <Box  display="flex"  justifyContent="center" alignItems="center" backgroundColor="#262033" padding="15px" width="calc(100% - 30px)" borderRadius="10px" >   
                  <Box  display="flex"  justifyContent="center" alignItems="center" gap="5px">   
                         <Img  w="38px"   src={coin} alt="coinImage" style={{objectFit:"cover"}} /> 
                         <Paragraph fontSize="40px" textAlign="start" fontWeight="600">   
                           {obj.pricing}
                         </Paragraph>
                  </Box>
            </Box>
          </CustomGrid>  )
         }
       
      </Grid>
      </Container>
    </HeaderDesign>
    </Box>
  );
}
 
export default WhitelabelHome;