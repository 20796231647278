 import "./page.css"
 import React, { useEffect, useMemo, useRef, useState } from 'react';
import  { Box, Container, Grid} from '@mui/material';
import styled from '@emotion/styled';
import Paragraph from '../../Paragraph';
// import {motion} from 'framer-motion';
import UperBar from '../../UperBar';
import axios from 'axios'; 
import {API_URL} from "../../../Constant/index" 
import LockerMenu from '../../../Components/LockerMenu'
import { Img } from '../../../Styles/Maintext.styles';
import Mediumtxt from '../../Mediumtxt';
import CustomButton from '../../CustomBtn'; 
import { motion, useScroll } from "framer-motion";
import {BLOG_URL} from  "../../../Constant/index"
import { useParams,Link } from 'react-router-dom'; 
import parse from 'html-react-parser';
import RefreshIcon from '@mui/icons-material/Refresh';
// const MotionGrid = motion(Grid);

const HeaderDesign = styled.div`
  min-height: 100dvh; 
  position: relative;
  z-index: 2;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  @media(max-width: 900px){
    min-height: 100vh;
  }
  
`
const BgVideo = styled.video`
  width:100%;
  height:calc(100vh - 0px);
  position:absolute; 
  top:0;
  left:0;
  object-fit:cover;
  display :block ;
  @media(max-width:499px){  
    display: none;
  }
` 
const BgVideo2 = styled.video`
  width:100%;
  height:calc(100vh - 100px);
  position:absolute; 
  /* z-index: 999; */
  top:0%;
  left:50%;
  transform: translate(-50%,0%);
  display: none;
  object-fit: cover;
  @media(max-width:499px){  
    display: block !important; 
  } 
`
const Spantype = styled.span`
  font-size: 54px; 
  @media(max-width:599px){  
    font-size: 21px !important; 
  }
`
 
const CustomGrid = styled(Grid)`  
   background-color: #707070; 
   border-radius: 10px;
   display: flex;  
   flex-direction: column; 
   justify-content: start; 
   align-items: center; 
   gap:10px;
`
const BlogDetail = () => { 
  // const [pagestate , setPagestate] =  useState(false);
  const [pagestate , setPagestate] =  useState(false);
  const [location, setLocation] = useState(null);  
  const [o_sys, setO_sys] =  useState(null); 
  const [filterdButton,setFilterdButton] = useState([]);
  const [Ips , setIps] = useState([]);
  const [status , setStatus] = useState("loading");
  const { scrollYProgress } = useScroll();
  const [isloading, setLoading] =  useState(true)
  useEffect(() => { 
    const timer =  setTimeout( async() => {
         try {
           const ipResponse = await axios.get('https://api.ipify.org?format=json') 
           // console.log(ipResponse.data.ip)
           const response = await axios.get(`https://ipapi.co/${ipResponse?.data.ip}/json/`)
           .then(function (response) {
               console.log("response",response);
               const data = response.data;  
                 // console.log(data)
                 setLocation({
                     ip: data?.ip, 
                     city: data?.city,
                     region: data?.region,
                     country: data?.country_name,
                     asn: data?.asn,
             isp: data?.org
           });
           })
           .catch(function (error) {
               console.error(error);
           });
           
         } catch (error) {
           console.error('Error fetching location:', error);
         }
     }, 0); // 3 seconds 
     return () => clearTimeout(timer);
   },[]); 
    
   const memoizedData = useMemo(() => {
     if (location) {
       // Perform expensive calculations or transformations here
       return location;
     }
     return [];
   }, [location]);
     
 
   useEffect(() => { 
     const timer =  setTimeout(async () => {
       // console.log(requestData)
       const endPoint = "getServerData";
       const endPoint2 = "getFreeServerData";
       const method = "GET";
       try {
         const response = await fetch(`${API_URL}${endPoint}`, {
           method,
           headers: {
             "content-type": "application/json",
           },
         });
         const responseFreeServer = await fetch(`${API_URL}${endPoint2}`, {
           method,
           headers: {
             "content-type": "application/json",
           },
         });
         const result = await response.json();
         const resultFreeServer = await responseFreeServer.json();
         // console.log("response", result);
         if (result?.message === "succes" && resultFreeServer?.message === "succes") {
           const ips = result?.data.map((server)=> server?.ip )
           const ipsFreeServer = resultFreeServer?.data.map((server)=> server?.ip )
           const newIpsList = [...ips,...ipsFreeServer]
           setIps(newIpsList)
         }
       } catch (err) {
         // setServerList([{}])
         console.error(err);
       } 
     },0)  
     return () => clearTimeout(timer);
   },[])
   useEffect(()=>{
     console.log("location?.ip",location?.ip); 
     console.log("ips",Ips.includes(location?.ip)); 
     if(Ips.length >0 && location){
 
       if(Ips?.includes(location?.ip)){  
         setStatus("Protected");    
         console.log("Status:", status)   
       }else{   
         setStatus("UnProtected");    
         console.log("status: Unrprotected")
         return;
       }
     } 
   },[Ips,location]) 
   
   const {id} = useParams(); 
   const [idd , setID] =  useState(id);
   const [content , setContent] =  useState("");

  
   const apiCall = async () => {
    // console.log(requestData)
    try {
      const response = await fetch(`https://blog.shadownode.org/wp-json/posts/details/${idd}`, {
        method: 'GET',
        headers: {
          "content-type": "application/json",
        },
      });
    
      const result = await response.json();
      console.log("BlogDetail", result);  
      setContent(result);
      setLoading(false)
    } catch (err) {
      // setServerList([{}])
      apiCall();
      console.error(err);
    } 
  }
   useEffect(() => { 
    if (id) {
      apiCall();
    }
    // const timer =  setTimeout(,0)   
    // return () => clearTimeout(timer);
  },[idd]) 
  
  const BoxEx = styled(Box)`
    &>*{
        color: white;
    } 
    img{ 
      width: 100%;
    }
 
  `
  const BoxLoader = styled(Box)`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    animation: rotate 1s infinite; 
   margin-top: 100px;
    @keyframes rotate { 
            50% { 
                transform: rotate(180deg); 
            } 
        } 
  `

//   const paragraphs = content.split('\n').filter(paragraph => paragraph.trim() !== '').map((paragraph, index) => (
//     <p key={index}>{parse(paragraph)}</p>
//   ));
  return (
    <Box position={'relative'}>
      {/* <BgVideo muted={true} autoPlay={true} playsInLine={true} loop={true}  >
       <source  src={DeskVid} type="video/mp4"/>
      </BgVideo>  */}
      <UperBar IP={memoizedData?.ip ? memoizedData?.ip : "loading"} location={memoizedData?.city ? `${memoizedData?.city}  ${memoizedData?.region}` :"loading"} ISP={ memoizedData?.isp ? ` ${memoizedData?.isp}` :"loading"} protected={status} />
      <HeaderDesign id="home">
      <LockerMenu /> 
      <Container maxWidth="lg"  sx={{ minHeight:{xs:"calc(100vh - 150px)",lg:"calc(100vh - 120px)"} ,display:"flex", flexDirection:"column", justifyContent:"start",marginTop:{xs:"50px",lg:"100px"},marginBottom:"10px",paddingLeft:{xs:"20px"},paddingRight:{xs:"20px"}}}> 
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress}}
      />  
          <CustomButton bgc="#1B1821" p="7px 8px" fs="16px" b="1px solid #707070" m="20px 0px" w="90px">  <Link to={`/Blog`}  style={{textDecoration:"none",color:"white"}}>Back</Link></CustomButton>    
          {/* <BoxLoader style={{background: 'red'}}>
                <RefreshIcon  fontSize="large" sx={{color: '#ffffff'}}/>
              </BoxLoader> */}
            { 
              isloading ? 
              <BoxLoader >
                <RefreshIcon  sx={{color: '#ffffff',fontSize:"50px"}}/>
              </BoxLoader>
              :
              <Box>  
                { 
                content?.featured_media?.url && <Img  src={content?.featured_media?.url} alt="pic" w="50%" style={{borderRadius:"10px"}} />
                } 
                <Box display="flex"  flexDirection="column" >
                <Mediumtxt   textAlign="left" fontSize={{xs:"19px",lg:"30px"}} fontWeight="600">{content?.title}</Mediumtxt>
                <Paragraph my="0px" textAlign="left" fontSize="16px">{content?.date}</Paragraph> 
                </Box>
                  <BoxEx dangerouslySetInnerHTML={{__html: content?.content}} /> 
              </Box>
            } 
      
           {/* <Paragraph my="0px" textAlign="left" fontSize="16px">{content?.excerpt}</Paragraph>  */}
      </Container>
    </HeaderDesign>
    </Box>
  );
}
 
export default BlogDetail;