import React, { useState, useEffect } from "react";
import { Container, styled } from "@mui/material";
import DesktopMenu from "./component/DesktopMenu";
import MobileMenu from "./component/MobileMenu";
import PurpleButton from "../PurpleButton";

const MainMenu = styled(Container)`
  background: linear-gradient(to right, rgba(9, 9, 9, 0.7), rgba(0, 0, 0, 0.7));
  /* backdrop-filter: blur(2px); */
  border: 1px solid #131313;
  border-radius: 10px;
  margin-top: 10px;
  position: fixed; 
  left: 50%; 
  transform: translateX(-50%);
  width: 100%;
  top: ${(props) => props.topPosition}px;
  transition: top 0.5s;
  z-index: 501 !important;
`;

const LockerMenu = (props) => {
  const [topPosition, setTopPosition] = useState(40);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollPos > currentScrollPos;

    setTopPosition(visible ? 40 : -100);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const menuList = [
    {
      title: "About",
      link: "#about",
      zindex: "10999999",
    },
    {
      title: "Features",
      link: "#features",
      zindex: "109999",
    },
    {
      title: "Passive Income",
      link: "#passiveincome",
      zindex: "109999",
    },
    {
      title: "Pricing",
      link: "https://app.shadownode.org",
      zindex: "109999",
    },
    {
      title: "Tokenomics",
      link: "#tokenomics",
      zindex: "109999",
    },
    {
      title: "White Label",
      link: "/whitelabel",
      target: "blank",
      zindex: "109999",
    }, 
    {
      title: "Contact Us",
      link: "/contact",
      target: "blank",
      zindex: "109999",
    }, 
    
    {
      title: "Whitepaper",
      link: "https://shadownode.gitbook.io/shadow-node-white-paper",
      target: "blank",
      zindex: "109999",
    },
    {
      title: "Blog",
      link: "/blog",
      target: "",
      zindex: "109999",
    },
    {
      title: (
        <PurpleButton href="" p="7px 25px" target="blank" sx={{fontSize:"17px !important",minWidth: "150px !important",borderRadius: "8px !important"}}>
          Dashboard
        </PurpleButton>
      ),
      link: "https://app.shadownode.org",
      p: "0 8px 0 5px",
    }, 
    // {
    //   title: (
    //     <PurpleButton  sx={{display:{xs:"inline-flex",sm:"none",md:"none",lg:"none"}}} p="2px 20px">Buy Subscription</PurpleButton>
    //   ),
    //   link: "https://app.shadownode.org",
    //   p: "0 8px 0 5px",
    // },

  ];

  return (
    <MainMenu maxWidth="lg"  topPosition={topPosition}>
      <DesktopMenu menuList={menuList} />
      <MobileMenu menuList={menuList} />
    </MainMenu>
  );
};

export default LockerMenu;
