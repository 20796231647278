import React from 'react';
import { Grid ,Container} from '@mui/material';
import Paragraph from '../../Paragraph';
import PurpleButton from '../../PurpleButton';
import shadowBoxLogo from '../../../../assets/Images/newDesign/shadowBoxLogo.png'
import { Img } from '../../../Styles/Maintext.styles';

const End = () => {
    return (
        <Container data-aos="fade-up"  className='colordBox' maxWidth="lg" sx={{position:"relative",zIndex:"500",padding:{xs:"50px 30px"},width:{xs:"calc(100% - 64px)"}}}>
            <Grid container display="flex" justifyContent="center">
                <Grid item xs={12} sm={8} md={6} display="flex" flexDirection="column" alignItems="center" gap="15px">
                    <Img w="140px"  src={shadowBoxLogo} alt="pic" style={{borderRadius:"12px",boxShadow:"0 0 50px #7E58FC"}} />
                    <Paragraph fontSize="clamp(1.875rem, 1.4464rem + 2.1429vw, 3.375rem)" fontWeight="600">Protect Your Online <br />Privacy Today
                    </Paragraph>
                    <Paragraph maxWidth="700px" fontSize="18px" fontWeight="600">
                        Get started with our VPN service today and safeguard your digital life from prying eyes
                    </Paragraph>
                    <PurpleButton  p="6px 30px"   href="#download"   sx={{position:"relative",zIndex:"9999",fontSize:"16px"}}>Download Now</PurpleButton>
                </Grid>   
            </Grid>
        </Container>
    );
}
 
export default End;