import logo from './logo.svg';
import './App.css';
import Main from './Views/Pages/Main';
import { createBrowserRouter , RouterProvider } from 'react-router-dom';
import Post_page from './Views/Pages/PostPage';
import Blog_detail from './Views/Pages/BlogDetail';
import Whitelabel from './Views/Pages/Whitelabel';
import Contact from './Views/Pages/contact';


function App() { 
  const router  = createBrowserRouter([   
    { 
      path : "/", 
      element  : 
        <Main />
    },   
    { 
      path : "/blog", 
      element  : 
        <Post_page /> 
    },  
    { 
      path : "/blog_detail/:id", 
      element  : <Blog_detail />
    },  
    { 
      path : "/whitelabel", 
      element  : 
        <Whitelabel />
    },  
    { 
      path : "/contact", 
      element  : 
        <Contact />
    }, 
    // { 
    //   path : "/whitelabel",  
    //   element : <SellerPage />
    // }
  ]); 

  return (
    <RouterProvider  router={router} />
  );
}

export default App;
