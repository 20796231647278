import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled';
import { Box, Container, Grid } from '@mui/material';
import comingSoonBg from '../../../../assets/Images/newDesign/comingSoonBg.png'
import shadowTalk from '../../../../assets/Images/newDesign/shadowTalk.png'
import shadowTalkVideo from '../../../../assets/Images/newDesign/Shadow Talk Design Preview.mp4'
import Paragraph from '../../Paragraph';
import { Img } from '../../../Styles/Maintext.styles';
const Blackbg = styled(Box)`
    background-image: url(${comingSoonBg});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    min-height: 100vh;
    padding: 50px 0;
    overflow: hidden;
    `
   const GridLay = styled(Grid)`
   &:hover{
     &>.MuiTypography-root{
      &>.colerd{
        color:#663CED;
     }
     &>.colerdLight{
      color:#B471F5;
   }
    }
  }
 ` 
const Video = styled.video`
width:100%;
height:auto;
object-fit:contain;
`


const  ComingSoon = () => {  
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, 3000); // 3 seconds

    // Clean up the timer on component unmount
    return () => clearTimeout(timer);
  }, []); 
  

  return (
    <Blackbg sx={{padding :"20px 0px !important"}}>   
      
      <div id="stars"></div>
        <div id="stars2"></div>
        {/* <div id="stars3"></div>   */}
    <Container maxWidth="lg"  data-aos="fade-up" sx={{position:"relative",zIndex:"500",  paddingLeft:{xs:"30px",sm:"16px",md:"32px",lg:"16px"},paddingRight:{xs:"30px",sm:"16px",md:"16px",lg:"16px"}}} >   
      <Grid container padding={{xs:"0px",lg:"0 10px"}} display='flex' gap="30px 0">
        <Grid item xs={12} sm={6} display="flex" flexDirection="column" justifyContent="start" alignItems="start">
          <Paragraph  variant="h4" fontSize="clamp(1.5rem, 1.3214rem + 0.8929vw, 2.125rem)" mb="20px" textAlign="left" fontWeight="600">Coming Soon</Paragraph>
          <Paragraph fontSize="clamp(1.875rem, 1.4464rem + 2.1429vw, 3.375rem)" textAlign="left"  lineHeight="1.4" fontWeight="600">
            Decentralized <br />
            Video Calling And <br />
            Messaging App 
          </Paragraph>
          <Box mt="20px" display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="15px"> 
            <Img w="170px" small_w="110px"  src={shadowTalk} alt="pic" />
            <Paragraph mb="20px" fontWeight="700" fontFamily="PoppinsB" fontSize={{xs:"14px",lg:"20px"}}  sx={{position:"relative",zIndex:"999"}}>Shadow Talk</Paragraph>
          </Box>
        </Grid> 
        <Grid item xs={12} sm={6}> 
          {show &&    
          <Video controls style={{position:"relative",zIndex:"999"}} > 
            <source src={shadowTalkVideo} type="video/mp4" />
          </Video> 
          }
        </Grid>
      </Grid>
    </Container>
    </Blackbg>
  )
}

export default  ComingSoon