import React, { useRef } from 'react';
import Home from '../../Components/Sections/S1-Home';
import Why from '../../Components/Sections/S2-Why';
import Faq from '../../Components/Sections/S6-Faq';
import Summary from '../../Components/Sections/S7-Summary';
import styled from '@emotion/styled';
import { useState,useEffect} from 'react';
import starGalaxyBg from "../../../assets/Images/newDesign/starGalaxyBg.jpg"
import Partnership from '../../Components/Sections/S9-Partnership'; 
import axios from 'axios';
import ReviewSection from '../../Components/Sections/S5-1-Reviews';
import End from '../../Components/Sections/S8-End';
import PoweredBy from '../../Components/Sections/S3-PoweredBy';
import WhySection from '../../Components/Sections/S3-NewWhy';
import RoadmapSection from '../../Components/Sections/S-RoadMap';
import ComingSoon from '../../Components/Sections/S-ComingSoon';
import MultipleIncome from '../../Components/Sections/S-MultipleIncome';
import TokenomicsV2 from '../../Components/Sections/S4-Tokennomics_Section';
import Availability from '../../Components/Sections/S3-Availability';
import Number_Sec from '../../Components/Sections/S10-Numbers';
import TopRighttImg from '../../../assets/Images/newDesign/right.png'
import BottomleftImg from '../../../assets/Images/newDesign/left.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import WagmiWallet from '../../../WalletConnectivity/Multi-Chain';
import LockerMenu from '../../Components/LockerMenu';
const Styleddiv = styled.div`
    width:100%;
    overflow: hidden;
    scroll-snap-align:start;
    min-height:100vh;
    padding:50px 0;
    
  background-image: url(${starGalaxyBg});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  @media(max-width: 899px){
    background-position: top;   
  background-size: auto 110vh;
  }
`
const FixedImage= styled.img`
  width:100%;
  height:auto;
  position:fixed;
  top:10%;
  left:-50%;
  z-index:1;
`
const Maindiv  = styled.div`
   overflow: hidden; 
`  
const  TopRightimg = styled.img` 
     position: fixed;
    width: 40%;
    top: 0%;
    right: 0%; 
    opacity: 0.5;
    z-index: 1; 
    @media(max-width:599px){
       width: 80% !important; 
    }
` 
const  BottomLeftimg = styled.img`
    position: fixed;
    width: 40%;
    bottom: 0%;
    left: 0%;
    z-index: 1;   
    opacity: 0.5!important;
    @media(max-width:599px){
       width: 80% !important; 
    }
`

 
const Main = () => {
    useEffect(()=>{
      AOS.init()
    })
    return ( 
      <>    
           <Maindiv>  
           <TopRightimg src={TopRighttImg} alt="topRightborderImg"  /> 
           <BottomLeftimg src={BottomleftImg} alt="BottomRightborderImg"  /> 
            <Home /> 
            <PoweredBy /> 
            <WhySection/> 
            <Availability />
            <Why/>  
            <WagmiWallet>
              <Number_Sec />
            </WagmiWallet>
            <MultipleIncome/> 
            <ComingSoon/> 
            <TokenomicsV2 />
            <RoadmapSection/>
            <Partnership /> 
            <ReviewSection/> 
            <Styleddiv>
         <div id="stars"></div>
        <div id="stars2"></div>
        {/* <div id="stars3"></div>   */}
                <Faq/>
                <End/>
            </Styleddiv>
            <Summary/>
            {/* <Footer /> */}
            <LockerMenu /> 
        </Maindiv>   
      </>
    );
}
export default Main;